var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "sprint-activity-log" }, [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "activity-wrapper" },
      _vm._l(_vm.projectActivity, function (activity, j) {
        return _c("div", { key: j, staticClass: "log-container" }, [
          activity.action_performed.name == "move"
            ? _c("div", { staticClass: "log-action" }, [
                _vm._m(1, true),
                _c(
                  "div",
                  { staticClass: "activity-action" },
                  [
                    _vm._l(_vm.teamMembers, function (member, i) {
                      return member.id == activity.user_performed_id
                        ? _c("span", { key: i, staticClass: "name" }, [
                            _vm._v(_vm._s(_vm.shortenName(member.name))),
                          ])
                        : _vm._e()
                    }),
                    _vm._v("\n          moved a\n          "),
                    _c("span", { staticClass: "action" }, [_vm._v("task")]),
                    activity.sprint_source_id
                      ? _c("span", [
                          _vm._v(
                            "\n            from Sprint " +
                              _vm._s(activity.sprint_source.sprint) +
                              "\n          "
                          ),
                        ])
                      : _vm._e(),
                    activity.sprint_destination_id
                      ? _c("span", [
                          _vm._v(
                            "\n            to Sprint " +
                              _vm._s(activity.sprint_destination.sprint) +
                              "\n          "
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(
                      "\n          •\n          " +
                        _vm._s(
                          _vm
                            .$moment(
                              activity.date_timezone
                                ? activity.date_timezone
                                : activity.created_at
                            )
                            .fromNow()
                        ) +
                        "\n        "
                    ),
                  ],
                  2
                ),
              ])
            : _vm._e(),
          activity.action_performed.name == "add"
            ? _c("div", { staticClass: "log-action" }, [
                _vm._m(2, true),
                _c(
                  "div",
                  { staticClass: "activity-action" },
                  [
                    _vm._l(_vm.teamMembers, function (member, i) {
                      return member.id == activity.user_performed_id
                        ? _c("span", { key: i, staticClass: "name" }, [
                            _vm._v(_vm._s(_vm.shortenName(member.name))),
                          ])
                        : _vm._e()
                    }),
                    _vm._v("\n          added a\n          "),
                    _c("span", { staticClass: "action" }, [_vm._v("task")]),
                    activity.sprint_source_id
                      ? _c("span", [
                          _vm._v(
                            "\n            to Sprint\n            " +
                              _vm._s(activity.sprint_source.sprint) +
                              "\n          "
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(
                      "\n          •\n          " +
                        _vm._s(
                          _vm
                            .$moment(
                              activity.date_timezone
                                ? activity.date_timezone
                                : activity.created_at
                            )
                            .fromNow()
                        ) +
                        "\n        "
                    ),
                  ],
                  2
                ),
              ])
            : _vm._e(),
          activity.action_performed.name == "completed"
            ? _c("div", { staticClass: "log-action" }, [
                _vm._m(3, true),
                _c(
                  "div",
                  { staticClass: "activity-action" },
                  [
                    _vm._l(_vm.teamMembers, function (member, i) {
                      return member.id == activity.user_performed_id
                        ? _c("span", { key: i, staticClass: "name" }, [
                            _vm._v(_vm._s(_vm.shortenName(member.name))),
                          ])
                        : _vm._e()
                    }),
                    _vm._v("\n          marked a\n          "),
                    _c("span", { staticClass: "action" }, [_vm._v("task")]),
                    activity.sprint_source_id
                      ? _c("span", [
                          _vm._v(
                            "\n            in Sprint\n            " +
                              _vm._s(activity.sprint_source.sprint) +
                              "\n          "
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(
                      "\n          as completed •\n          " +
                        _vm._s(
                          _vm
                            .$moment(
                              activity.date_timezone
                                ? activity.date_timezone
                                : activity.created_at
                            )
                            .fromNow()
                        ) +
                        "\n        "
                    ),
                  ],
                  2
                ),
              ])
            : _vm._e(),
          activity.action_performed.name == "assign"
            ? _c("div", { staticClass: "log-action" }, [
                _vm._m(4, true),
                _c(
                  "div",
                  { staticClass: "activity-action" },
                  [
                    _vm._l(_vm.teamMembers, function (member, i) {
                      return member.id == activity.user_performed_id
                        ? _c("span", { key: i, staticClass: "name" }, [
                            _vm._v(_vm._s(_vm.shortenName(member.name))),
                          ])
                        : _vm._e()
                    }),
                    _vm._v("\n          assigned\n          "),
                    _c("span", { staticClass: "action" }, [_vm._v("a task")]),
                    _vm._v("\n          to •\n          "),
                    _vm._l(_vm.teamMembers, function (member, i) {
                      return member.id == activity.user_performed_second_id
                        ? _c("span", { key: i, staticClass: "name" }, [
                            _vm._v(_vm._s(_vm.shortenName(member.name))),
                          ])
                        : _vm._e()
                    }),
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm
                            .$moment(
                              activity.date_timezone
                                ? activity.date_timezone
                                : activity.created_at
                            )
                            .fromNow()
                        ) +
                        "\n        "
                    ),
                  ],
                  2
                ),
              ])
            : _vm._e(),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "activity-title-wrap" }, [
      _c("div", { staticClass: "activity-title" }, [
        _c("div", { staticStyle: { "margin-top": "4px" } }, [
          _vm._v("ACTIVITY"),
        ]),
        _c("div", { staticClass: "new-activity-crumb" }, [_vm._v("NEW")]),
      ]),
      _c("div", { staticClass: "div-setting" }, [
        _c("div", [
          _c("img", { attrs: { src: "/img/dashboard/expand-settings.svg" } }),
        ]),
        _c("div", [
          _c("img", { attrs: { src: "/img/dashboard/settings-icon.svg" } }),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "time-connector" }, [
      _c("div", [
        _c("img", { attrs: { src: "/img/dashboard/time-icon.svg" } }),
      ]),
      _c("div", { staticClass: "log-connect-line" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "time-connector" }, [
      _c("div", [
        _c("img", { attrs: { src: "/img/dashboard/time-icon.svg" } }),
      ]),
      _c("div", { staticClass: "log-connect-line" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "time-connector" }, [
      _c("div", [
        _c("img", { attrs: { src: "/img/dashboard/time-icon.svg" } }),
      ]),
      _c("div", { staticClass: "log-connect-line" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "time-connector" }, [
      _c("div", [
        _c("img", { attrs: { src: "/img/dashboard/time-icon.svg" } }),
      ]),
      _c("div", { staticClass: "log-connect-line" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }