<template>
  <div class="sprint-activity-log">
    <div class="activity-title-wrap">
      <div class="activity-title">
        <div style="margin-top: 4px">ACTIVITY</div>
        <div class="new-activity-crumb">NEW</div>
      </div>
      <div class="div-setting">
        <div><img src="/img/dashboard/expand-settings.svg" /></div>
        <div><img src="/img/dashboard/settings-icon.svg" /></div>
      </div>
    </div>
    <div class="activity-wrapper">
      <div
        class="log-container"
        v-for="(activity, j) in projectActivity"
        :key="j"
      >
        <div class="log-action" v-if="activity.action_performed.name == 'move'">
          <div class="time-connector">
            <div><img src="/img/dashboard/time-icon.svg" /></div>
            <div class="log-connect-line"></div>
          </div>
          <div class="activity-action">
            <span
              class="name"
              v-for="(member, i) in teamMembers"
              :key="i"
              v-if="member.id == activity.user_performed_id"
              >{{ shortenName(member.name) }}</span
            >
            moved a
            <span class="action">task</span>
            <span v-if="activity.sprint_source_id">
              from Sprint {{ activity.sprint_source.sprint }}
            </span>
            <span v-if="activity.sprint_destination_id">
              to Sprint {{ activity.sprint_destination.sprint }}
            </span>
            <!-- • {{ $moment(activity.created_at).fromNow() }} -->
            •
            {{
              $moment(
                activity.date_timezone
                  ? activity.date_timezone
                  : activity.created_at
              ).fromNow()
            }}
          </div>
        </div>
        <div class="log-action" v-if="activity.action_performed.name == 'add'">
          <div class="time-connector">
            <div><img src="/img/dashboard/time-icon.svg" /></div>
            <div class="log-connect-line"></div>
          </div>
          <div class="activity-action">
            <span
              class="name"
              v-for="(member, i) in teamMembers"
              :key="i"
              v-if="member.id == activity.user_performed_id"
              >{{ shortenName(member.name) }}</span
            >
            added a
            <span class="action">task</span>
            <span v-if="activity.sprint_source_id">
              to Sprint
              {{ activity.sprint_source.sprint }}
            </span>
            •
            {{
              $moment(
                activity.date_timezone
                  ? activity.date_timezone
                  : activity.created_at
              ).fromNow()
            }}
          </div>
        </div>
        <div
          class="log-action"
          v-if="activity.action_performed.name == 'completed'"
        >
          <div class="time-connector">
            <div><img src="/img/dashboard/time-icon.svg" /></div>
            <div class="log-connect-line"></div>
          </div>
          <div class="activity-action">
            <span
              class="name"
              v-for="(member, i) in teamMembers"
              :key="i"
              v-if="member.id == activity.user_performed_id"
              >{{ shortenName(member.name) }}</span
            >
            marked a
            <span class="action">task</span>
            <span v-if="activity.sprint_source_id">
              in Sprint
              {{ activity.sprint_source.sprint }}
            </span>
            as completed •
            {{
              $moment(
                activity.date_timezone
                  ? activity.date_timezone
                  : activity.created_at
              ).fromNow()
            }}
          </div>
        </div>
        <div
          class="log-action"
          v-if="activity.action_performed.name == 'assign'"
        >
          <div class="time-connector">
            <div><img src="/img/dashboard/time-icon.svg" /></div>
            <div class="log-connect-line"></div>
          </div>
          <div class="activity-action">
            <span
              class="name"
              v-for="(member, i) in teamMembers"
              :key="i"
              v-if="member.id == activity.user_performed_id"
              >{{ shortenName(member.name) }}</span
            >
            assigned
            <span class="action">a task</span>
            <!-- <span v-if="activity.sprint_source_id">
              to
              {{ activity.sprint_source.sprint }}
            </span> -->
            to •
            <span
              class="name"
              v-for="(member, i) in teamMembers"
              :key="i"
              v-if="member.id == activity.user_performed_second_id"
              >{{ shortenName(member.name) }}</span
            >
            {{
              $moment(
                activity.date_timezone
                  ? activity.date_timezone
                  : activity.created_at
              ).fromNow()
            }}
          </div>
        </div>
      </div>
      <!-- <div class="activity-comment">
      <div class="username-profile">
        <v-avatar
          :size="16"
          color="grey lighten-4"
          class="profile_bg"
          :style="{
            backgroundImage: `url(${profile})`,
          }"
        ></v-avatar>
        <div class="commentor-name">Amaka • <span>2h ago</span></div>
      </div>
      <div class="msg-connector">
        <div class="message-connect"></div>
        <div class="messages-box-div">
          Amaka mentioned <span>@Nasirudeen</span> in this comment that says
          nothing but still takes 2 lines
        </div>
      </div>
    </div> -->
    </div>
    <!-- <div class="log-container">
      <div class="log-action">
        <div class="time-connector">
          <div><img src="/img/dashboard/time-icon.svg" /></div>
          <div class="log-connect-line"></div>
        </div>
        <div class="activity-action">
          <span class="name">Nasirudeen</span> moved a
          <span class="action">task</span>
          from Sprint 2 to Sprint 4 • 40m ago
        </div>
      </div>
    </div> -->
    <!-- <div class="log-container">
      <div class="log-action">
        <div class="time-connector">
          <div><img src="/img/dashboard/time-icon.svg" /></div>
          <div class="log-connect-line"></div>
        </div>
        <div class="activity-action">
          <span class="name">Marcus</span> moved a
          <span class="action">task</span>
          from Sprint 2 to Sprint 4 • 40m ago
        </div>
      </div>
    </div>
    <div class="log-container">
      <div class="log-action">
        <div class="time-connector">
          <div><img src="/img/dashboard/time-icon.svg" /></div>
          <div class="log-connect-line"></div>
        </div>
        <div class="activity-action">
          <span class="name">Chibuzor</span> moved a
          <span class="action">task</span>
          from Sprint 2 to Sprint 4 • 40m ago
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import shortName from "@/mixins/shortName";
import computeTask from "@/mixins/computeTask";
export default {
  props: {
    teamMembers: {
      type: Array,
      required: true,
    },
    projectActivity: {
      type: Array,
      required: true,
    },
  },
  components: {},
  mixins: [shortName, computeTask],
  data: () => ({
    profile: "/img/onboarding/company/profile.jpeg",
  }),

  created() {},
  methods: {},
  computed: {},
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}
.sprint-activity-log {
  padding: 20px 24px 40px 24px;
  background: #ffffff;
  border-radius: 4px;
  width: 100%;
  height: 367px;
}
.activity-title-wrap {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
}
.activity-title {
  display: flex;
  font-size: 12px;
  line-height: 140%;
  color: #979da5;
}
.new-activity-crumb {
  background: #54cf94;
  border-radius: 90px;
  padding: 6px 8px;
  font-size: 10px;
  line-height: 12px;
  color: #ffffff;
  margin-left: 8px;
}
.div-setting {
  display: flex;
}
.div-setting div:first-child {
  margin-right: 16px;
}
.log-action {
  display: flex;
}
.activity-action {
  font-size: 14px;
  line-height: 130%;
  color: #979da5;
  margin-left: 8px;
  margin-top: 2px;
}

.activity-action .name {
  color: #000000;
}
.messages-box-div span,
.activity-action .action {
  color: #0781f2;
}
.time-connector {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.log-connect-line {
  background: #e4e5e7;
  border-radius: 3px;
  width: 1px;
  height: 22px;
  /* margin-left: 8px; */
  margin-top: 3px;
  margin-bottom: 1px;
}
.username-and-connector {
  display: flex;
}
.username-profile {
  display: flex;
}
.commentor-name {
  margin-left: 8px;
  font-size: 14px;
  line-height: 130%;
  color: #000000;
}
.commentor-name span {
  color: #979da5;
}
.msg-connector {
  display: flex;
}
.message-connect {
  background: #e4e5e7;
  border-radius: 3px;
  width: 1px;
  min-height: 22px;
  margin-left: 8px;
  margin-top: 3px;
}
.messages-box-div {
  text-align: left;
  background: #f7f7f8;
  border-radius: 4px;
  margin-left: 16px;
  padding: 12px;
  font-size: 14px;
  line-height: 130%;
  color: #53585f;
  width: 508px;
  margin-top: 8px;
  margin-bottom: 24px;
}
.activity-wrapper {
  height: 287px;
  overflow-y: scroll;
}
</style>
