<template>
  <div class="modal-container">
    <div class="modal-wrap">
      <div class="dialog-header">
        <img v-if="isMeetingHold" src="/img/dashboard/arrow-back.svg" alt="" />
        <div class="dialog-title">Rate the meeting</div>
        <div class="close-btn" @click="closeDialog">
          <img src="/img/onboarding/cancel_close.svg" alt="" />
        </div>
      </div>
      <div v-if="isRatingForm">
        <div class="dialog-content">
          <div class="rating-form">
            <div class="rating-msg">
              How would you rate your first meeting with the {{ meetingTitle }}?
            </div>
            <div class="rating-wrapper">
              <div class="single-rating" v-for="(rate, i) in ratings" :key="i">
                <div
                  class="rate-icon"
                  @click="
                    form.value = rate.value;
                    selectedRating = rate.selected;
                  "
                >
                  <img v-if="form.value == rate.value" :src="rate.selected" />
                  <img v-else :src="rate.img" />
                </div>
                <div
                  class="rating-name"
                  :class="{ 'selected-rate': form.value == rate.value }"
                >
                  {{ rate.name }}
                </div>
              </div>
            </div>
          </div>
          <div class="form-grouped">
            <textarea
              class="form-control"
              name="overview"
              maxlength="160"
              v-model="form.comment"
              rows="4"
              id="textarea"
              style="height: unset"
              placeholder="Add comment (optional)"
            ></textarea>
          </div>
        </div>
        <div class="dialog-footer">
          <button
            @click="handleMeetingStatus"
            class="grupa-white-btn"
            style="margin-right: 16px"
          >
            Meeting didn’t hold
          </button>
          <button class="grupa-blue-btn" @click="submitRating">
            Submit Rating
            <img
              src="/img/lightbox/preloader.gif"
              style="margin-left: 20px"
              class="button-spinner"
              v-show="ratingSpinner"
            />
          </button>
        </div>
      </div>
      <div v-if="ratingSucess">
        <div class="dialog-content">
          <div class="feedback-div">
            <div class="success-div">
              Thank you for your feedback! This helps us mointor and improve the
              quality of your experience.
            </div>
            <div class="selected-rating-icon">
              <img :src="selectedRating" alt="" />
            </div>
            <div class="success-div bottom-success-mg">
              “{{ form.comment ? form.comment : "" }}”
            </div>
            <div class="close-btn-div">
              <button @click="closeDialog" class="grupa-blue-btn">Close</button>
            </div>
          </div>
        </div>
      </div>
      <div v-if="isMeetingHold">
        <div class="dialog-content">
          <div class="rating-form">
            <div class="rating-msg">Why didn’t the meeting hold?</div>
            <div class="form-grouped" style="margin-top: 8px">
              <textarea
                class="form-control"
                name="overview"
                maxlength="160"
                v-model="failForm.reason"
                rows="4"
                id="textarea"
                style="height: unset"
                placeholder="Add comment (optional)"
              ></textarea>
            </div>
            <div class="rating-msg" style="margin-top: 24px">
              Would you like to reschedule the meeting? Or have support help out
            </div>
          </div>
        </div>
        <div class="dialog-footer">
          <button class="grupa-white-btn" style="margin-right: 16px">
            Contact Support
          </button>
          <button class="grupa-blue-btn">Reshedule Meeting</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    closeDialog: {
      type: Function,
      required: true,
    },
    meetingTitle: {
      type: String,
      required: true,
    },
    form: {
      type: Object,
      required: true,
    },
    submitRating: {
      type: Function,
      required: true,
    },
    ratingSpinner: {
      type: Boolean,
      required: true,
    },
    ratingSucess: {
      type: Boolean,
      required: true,
    },
    failForm: {
      type: Object,
      required: true,
    },
    isMeetingHold: {
      type: Boolean,
      required: true,
    },
    isRatingForm: {
      type: Boolean,
      required: true,
    },
    handleMeetingStatus: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      ratings: [
        {
          name: "Abysmal",
          value: 1,
          img: "/img/rating/sad-icon.svg",
          selected: "/img/rating/sad-selected.svg",
        },
        {
          name: "Disappointing",
          value: 2,
          img: "/img/rating/boring-icon.svg",
          selected: "/img/rating/boring-selected.svg",
        },
        {
          name: "Good enough",
          value: 3,
          img: "/img/rating/good-icon.svg",
          selected: "/img/rating/good-selected.svg",
        },
        {
          name: "Amazing",
          value: 4,
          img: "/img/rating/smiling-icon.svg",
          selected: "/img/rating/smiling-selected.svg",
        },
      ],
      selected: 0,
      selectedRating: "/img/rating/smiling-selected.svg",
    };
  },
  created() {},
  methods: {},
};
</script>

<style scoped>
.modal-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-wrap {
  background: #ffffff;
  /* box-shadow: 2px 2px 20px 1px; */
  /* padding: 15px 0px 34px 0px; */
  flex-direction: column;
  min-width: 332px;
  /* min-height: 50vh; */
  box-shadow: 2px 2px 30px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
}
.dialog-header {
  background: #f7f7f8;
  box-shadow: 0px 1px 2px rgba(30, 27, 34, 0.1);
  border-radius: 4px 4px 0px 0px;
  padding: 16px;
  display: flex;
  justify-content: space-between;
}
.dialog-title {
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
  text-align: left;
  color: #1b1e22;
}
.dialog-content {
  padding: 24px;
}
.dialog-footer {
  padding: 0px 16px 16px 16px;
  display: flex;
  justify-content: flex-end;
}
.close-btn {
  /* float: right; */
  cursor: pointer;
}

.rating-form {
  width: 356px;
}
.rating-msg {
  text-align: left;
}
.rating-wrapper {
  display: flex;
  margin-top: 24px;
}
.rate-icon {
  margin-top: 8px;
  cursor: pointer;
}
.rating-name {
  font-size: 12px;
  line-height: 150%;
  color: #979da5;
}
.single-rating {
  margin-right: 37px;
}
.single-rating:nth-of-type(4) {
  margin-right: 0px;
}
.form-grouped {
  margin-top: 24px;
}
.form-control {
  margin-bottom: 0px;
}
.selected-rate {
  color: #1b1e22;
}
.feedback-div {
  width: 344px;
}
.success-div {
  font-size: 14px;
  line-height: 130%;
  text-align: center;
  color: #1b1e22;
}
.selected-rating-icon {
  margin-top: 24px;
}
.bottom-success-mg {
  margin-top: 24px;
  color: #979da5;
}
.close-btn-div {
  margin-top: 24px;
}
</style>


