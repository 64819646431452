var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal-container" }, [
    _c("div", { staticClass: "modal-wrap" }, [
      _c("div", { staticClass: "dialog-header" }, [
        _c("div", { staticClass: "dialog-title" }, [
          _vm._v("\n        " + _vm._s(_vm.dialogTitle) + "\n      "),
        ]),
        _c(
          "div",
          { staticClass: "close-btn", on: { click: _vm.closeDialog } },
          [
            _c("img", {
              attrs: { src: "/img/onboarding/cancel_close.svg", alt: "" },
            }),
          ]
        ),
      ]),
      _c("div", { staticClass: "dialog-content" }, [
        _vm._m(0),
        _c("div", { staticClass: "act-ques" }, [
          _vm._v(
            "\n        Was the activity for Day " +
              _vm._s(_vm.currentDesignSprint.day) +
              " done?\n      "
          ),
        ]),
      ]),
      _c("div", { staticClass: "dialog-footer" }, [
        _c(
          "button",
          {
            staticClass: "grupa-grey-btn",
            staticStyle: { "margin-right": "24px" },
            on: {
              click: function ($event) {
                return _vm.handleDailyStatus(2)
              },
            },
          },
          [
            _vm._v("\n        No\n        "),
            _c("img", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.noSpinner,
                  expression: "noSpinner",
                },
              ],
              staticClass: "button-spinner",
              staticStyle: { "margin-left": "20px" },
              attrs: { src: "/img/lightbox/preloader.gif" },
            }),
          ]
        ),
        _c(
          "button",
          {
            staticClass: "grupa-blue-btn",
            on: {
              click: function ($event) {
                return _vm.handleDailyStatus(1)
              },
            },
          },
          [
            _vm._v("\n        Yes\n        "),
            _c("img", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.yesSpinner,
                  expression: "yesSpinner",
                },
              ],
              staticClass: "button-spinner",
              staticStyle: { "margin-left": "20px" },
              attrs: { src: "/img/lightbox/preloader.gif" },
            }),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "activity-tract" }, [
      _vm._v(
        "\n        Help us keep track of the design sprint progress. You can always set\n        the status of everyday’s activity to "
      ),
      _c("span", [_vm._v("Done")]),
      _vm._v(" or\n        "),
      _c("span", [_vm._v("Not Done")]),
      _vm._v(" to show progress\n      "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }