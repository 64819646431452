<template>
  <div class="modal-container">
    <div class="modal-wrap">
      <div class="dialog-header">
        <div class="dialog-title">
          {{ dialogTitle }}
        </div>
        <div class="close-btn" @click="closeDialog">
          <img src="/img/onboarding/cancel_close.svg" alt="" />
        </div>
      </div>
      <div class="dialog-content">
        <div class="activity-tract">
          Help us keep track of the design sprint progress. You can always set
          the status of everyday’s activity to <span>Done</span> or
          <span>Not Done</span> to show progress
        </div>
        <div class="act-ques">
          Was the activity for Day {{ currentDesignSprint.day }} done?
        </div>
      </div>
      <div class="dialog-footer">
        <button
          @click="handleDailyStatus(2)"
          class="grupa-grey-btn"
          style="margin-right: 24px"
        >
          No
          <img
            src="/img/lightbox/preloader.gif"
            style="margin-left: 20px"
            class="button-spinner"
            v-show="noSpinner"
          />
        </button>

        <button @click="handleDailyStatus(1)" class="grupa-blue-btn">
          Yes
          <img
            src="/img/lightbox/preloader.gif"
            style="margin-left: 20px"
            class="button-spinner"
            v-show="yesSpinner"
          />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    closeDialog: {
      type: Function,
      required: true,
    },
    dialogTitle: {
      type: String,
      required: true,
    },
    handleDailyStatus: {
      type: Function,
      required: true,
    },
    currentDesignSprint: {
      type: Object,
      required: true,
    },
    noSpinner: {
      type: Boolean,
    },
    yesSpinner: {
      type: Boolean,
    },
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style scoped>
.modal-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-wrap {
  background: #ffffff;
  /* box-shadow: 2px 2px 20px 1px; */
  /* padding: 15px 0px 34px 0px; */
  flex-direction: column;
  min-width: 332px;
  /* min-height: 50vh; */
  box-shadow: 2px 2px 30px #0000004d;
  border-radius: 5px;
}
.dialog-header {
  background: #f7f7f8;
  box-shadow: 0px 1px 2px rgba(30, 27, 34, 0.1);
  border-radius: 4px 4px 0px 0px;
  padding: 16px;
  display: flex;
  justify-content: space-between;
}
.dialog-title {
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
  text-align: left;
  color: #1b1e22;
}
.dialog-content {
  padding: 24px;
}
.dialog-footer {
  padding: 0px 16px 16px 16px;
  display: flex;
  justify-content: flex-end;
}
.close-btn {
  /* float: right; */
  cursor: pointer;
}

.activity-tract {
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
  width: 356px;
  text-align: left;
}
.activity-tract span:first-child {
  color: #1fa564;
}
.activity-tract span:last-child {
  color: #a5271f;
}
.act-ques {
  font-size: 14px;
  line-height: 130%;
  margin-top: 24px;
  color: #6f7680;
  text-align: left;
}
</style>


